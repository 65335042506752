






















































































































































































































































































@import './header.css';

#pc-header {
  z-index: 2000;
}

.dropdown-menu > li > a:hover {
  background: #4b8fff !important;
}

.shadow {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1) !important;
}

// .anniversary-navbar {
//   .navbar-brand {
//     display: flex;
//     align-items: center;
//     padding: 0 20px 0 40px !important;
//   }
// }

.navbar-user {
  position: relative;
  background-color: #fff;
  .login-tip {
    position: absolute;
    z-index: 30;
    top: 40px;
    left: 50%;
    margin-left: -85px;
    width: 170px;
    height: 52px;
    animation: step-reverse 0.8s infinite;
    img {
      width: 100%;
    }
  }

  @keyframes step-reverse {
    0% {
      top: 55px;
    }

    50% {
      top: 48px;
    }

    100% {
      top: 55px;
    }
  }
}
