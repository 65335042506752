@media (max-width: 1160px) {
  .es-header .navbar-user {
    right: 20px;
  }

  .es-header .nav.navbar-nav {
    position: absolute;
    left: 280px;
    right: 50px;
  }
}

.nav.user-nav>li.nav-hover:hover .dropdown-menu,
.user-nav>li.open .dropdown-menu,
.navbar-nav>li.open .dropdown-menu,
.navbar-nav>li:hover .dropdown-menu {
  display: block;
}

.dropdown-header .item.header-name .picture-vip {
  position: absolute;
  right: 0;
  font-size: 65px;
  height: 24px;
}

.navbar-nav {
  float: left;
  margin: 0;
  height: 80px;
}

.navbar-nav>li {
  float: left;
}

.navbar-nav>li>a {
  padding-top: 15px;
  padding-bottom: 15px;
}

.anniversary-tab {
  padding: 0 !important;
  height: 100%;
}

.anniversary-tab img {
  width: calc(100% - 6px);
  max-height: 100%;
}


.container>.navbar-header,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container-fluid>.navbar-collapse {
  margin-right: 0;
  margin-left: 0;
}

.navbar>.container .navbar-brand,
.navbar>.container-fluid .navbar-brand {
  margin-left: -10px;
}

.navbar-header {
  float: left;
  height: 80px;
}

.navbar-collapse {
  width: auto;
  border-top: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-collapse.collapse {
  display: block !important;
  height: auto !important;
  padding-bottom: 0;
  overflow: visible !important;
}

.navbar-collapse.in {
  overflow-y: visible;
}

.navbar-fixed-top .navbar-collapse,
.navbar-static-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

.es-header .navbar-menu ul {
  border: none;
}

.es-header .student-id {
  color: #8a9499;
  overflow: visible;
  white-space: pre;
  font-size: 14px;
}

.navbar {
  margin-bottom: 0;
  z-index: 99;
  border-radius: 0;
}

.navbar-collapse {
  border-top: none;
}

.es-header {
  background-color: #fff;
  height: 80px;
  border: none;
  padding: 0;
  font-size: 18px;
}

.es-header .container {
  position: relative;
}

.es-header .collapse {
  display: block;
}

.es-header .navbar-mobile .navbar-more {
  color: #313131;
}

@media (max-width: 767px) {
  .es-header .navbar-header {
    text-align: center;
    float: none;
  }

  .es-header .navbar-header .navbar-brand {
    display: inline-block;
    float: none;
  }
}

.es-header .navbar-header .navbar-brand {
  height: 80px;
  line-height: 90px;
  padding: 0 30px;
  color: #313131;
  text-align: center;
}

.es-header .navbar-header .navbar-brand>img {
  height: 100%;
  width: auto;
}

.es-header .nav.navbar-nav>li.nav-hover {
  position: relative;
  height: 100%;
}

.nav-hover .el-icon {
  font-size: 16px;
}

.nav-hover .notification-icon-container {
  padding: 14.4px 5px 20px!important;
}

.nav-hover .notification-icon {
  font-size: 42px;
  color: #313131;
}

.nav.user-nav>li>a,
.es-header.navbar li.nav-hover>a,
.nav.user-nav a.dropdown-toggle,
.es-header .nav.navbar-nav>li>a {
  display: inline-block;
  padding: 26px 24px 30px;
  line-height: 20px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 4px solid transparent;
  font-size: 18px;
  font-weight: 400;
  color: #313131;
  background: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.nav.user-nav>li:hover>a,
.es-header.navbar li.nav-hover.open>a,
.es-header.navbar li.nav-hover:hover>a,
.es-header.navbar li.nav-hover:focus>a,
.nav.user-nav>li.nav-hover.open>a.dropdown-toggle,
.nav.user-nav>li.nav-hover:hover>a.dropdown-toggle,
.nav.user-nav>li.nav-hover:focus>a.dropdown-toggle,
.es-header .nav.navbar-nav>li.nav-hover:hover>a,
.es-header .nav.navbar-nav>li.nav-hover:focus>a,
.es-header .nav.navbar-nav>li.nav-hover.active>a,
.es-header .nav.navbar-nav>li.nav-hover.open>a {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-top: 4px solid #1e73ff;
  background: none;
  color: #313131;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.es-header .nav.navbar-nav>li.nav-hover .dropdown-menu {
  min-width: 100%;
  text-align: center;
  font-size: 18px;
  position: absolute;
  right: 0;
  left: auto;
  padding: 0 0 10px;
  border-radius: 0;
}

.es-header .nav.navbar-nav .navbar-toggle {
  padding: 5px 15px;
  background: #1abc9c;
}

.es-header .nav.navbar-nav .navbar-toggle .icon-bar {
  background: #fff;
}

.es-header .navbar-form {
  margin: 15px 10px;
}

@media (max-width: 1199px) {
  .es-header .navbar-form {
    margin: 15px 0;
  }
}

.navbar-mobile {
  position: absolute;
  top: 0;
  left: 0;
}

.navbar-mobile .navbar-more {
  position: relative;
  padding: 30px 15px;
  line-height: 20px;
  display: block;
  color: #fff;
  z-index: 1001;
}

.navbar-more .es-icon {
  font-size: 18px;
}

.navbar-mobile .nav {
  float: none;
  text-align: left;
  padding: 0 20px;
}

.navbar-mobile .dropdown-menu>li>a {
  color: #fff;
}

.navbar-mobile .navbar-form {
  width: 80%;
  margin: 60px auto 20px auto;
}

.navbar-mobile .navbar-form .form-control {
  width: 100%;
}

.navbar-form {
  margin: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-form .form-group {
  position: relative;
}

.navbar-form .form-control {
  height: 30px;
  width: 100px;
  border-radius: 20px;
  line-height: 28px;
  padding: 0 24px 0 12px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #e1e1e1;
}

.navbar-form .form-control.active {
  width: 150px;
}

.navbar-form .button {
  position: absolute;
  right: 2px;
  top: 6px;
  border: none;
  background: none;
}

.navbar-form .button.search {
  top: 3px;
}

.nav>li.nav-more li .dropdown-menu {
  left: 100%;
  top: 0;
}

.nav>li.nav-more.open li:hover .dropdown-menu {
  display: block;
}

.nav>li.nav-more .open>a,
.nav>li.nav-more .open.active>a,
.nav>li.nav-more .open>a:hover,
.nav>li.nav-more .open>a:focus {
  background: #1e73ff !important;
  color: #fff !important;
}

.head-right {
  z-index: 1;
  position: absolute;
  top: 0;
  background-color: #fff;
  right: 0;
  padding-right: 50px;
}

.navbar-user {
  float: right;
}

.search-entry {
  width: 42px;
  display: flex;
  align-items: center;
}

.search-entry .search-icon {
  font-size: 42px;
  color: #313131;
}

.nav.user-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.nav.user-nav>li {
  height: 80px;
}

.nav.user-nav>li>a.dropdown-toggle {
  padding: 16px 19px 20px;
}

.nav.user-nav>li>a.dropdown-toggle .avatar-face {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.nav.user-nav>li>a.dropdown-toggle .avatar-vip {
  width: 23px;
  height: 23px;
  right: 14px;
  bottom: 14px;
  overflow: hidden;
}

.nav.user-nav>li.nav-hover .dropdown-menu {
  position: absolute;
  right: 0;
  left: auto;
  min-width: 100%;
  border-radius: 0;
  padding: 0 0 10px;
  width: 220px;
  border: 1px solid #e6e6e6;
}

.dropdown-menu .dropdown-header {
  padding: 15px 0 15px 30px;
}

.nav.user-nav>li.nav-hover .dropdown-menu .nav-item {
  font-size: 18px;
}

.nav.user-nav>li.nav-hover .dropdown-menu .nav-item>a {
  padding-left: 30px;
}

.nav.user-nav>li.nav-hover .dropdown-menu .nav-item .es-icon {
  font-size: 18px;
}

.nav.user-nav>li>a:hover,
.nav.user-nav>li>a:focus {
  color: #fff;
  background: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.dropdown-header .item.header-name {
  font-size: 18px;
  font-weight: 500;
}

.dropdown-header .item.header-name .nickname {
  display: inline-block;
  width: 100%;
  padding-right: 65px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nav.user-nav .dropdown-menu {
  width: 180px;
  background: #fff;
  left: auto;
  right: 0;
}

.nav.user-nav .dropdown-menu>li {
  width: 100%;
}

.nav.user-nav .dropdown-menu>li>a {
  padding-left: 10px;
}

.nav.user-nav .dropdown-menu>li>a:hover .num {
  background: #fff;
  color: #1e73ff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.nav.user-nav .dropdown-menu>li i {
  padding: 0 10px;
}

.nav.user-nav .dropdown-menu>li .num {
  background: #999;
  padding: 0 10px;
  border-radius: 20px;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.es-header .nav.user-nav>li>a {
  color: #313131;
}

.es-header .nav.user-nav>li>a:hover,
.es-header .nav.user-nav>li>a:focus {
  color: #313131;
}

.nav.user-nav>li.nav-hover .dropdown-menu.dropdown-notification {
  width: 320px;
  padding: 0;
}

.dropdown-notification .notification-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 14px 20px;
  font-size: 14px;
}

.dropdown-notification li+li {
  border-top: 1px solid #eee;
}

.nav.user-nav>li .dot {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  line-height: 19px;
  left: 22px;
  top: 21px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  border-radius: 50%;
  background-color: #e83d2c;
  border: 1px solid #fff;
}

.dropdown-notification .notification-item .notify-title {
  font-size: 18px;
  font-weight: 500;
  color: #002333;
}

.dropdown-notification .notification-item .who {
  font-size: 18px;
  font-weight: 400;
}

.dropdown-notification .notification-item .time {
  font-size: 12px;
}

.dropdown-notification .notification-item a.btn-read-all {
  color: #aaa;
  cursor: pointer;
}

.dropdown-notification .notification-item a.btn-read-all:hover,
.dropdown-notification .notification-item a.btn-read-all:focus,
.dropdown-notification .notification-item a.btn-read-all:active {
  color: #1e73ff;
}

.dropdown-notification .btn-show-all {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #1e73ff;
}

.dropdown-notification .notification-item .notify-title {
  font-size: 18px;
  font-weight: 500;
  color: #002333;
}

.dropdown-notification .notification-item .who {
  overflow: hidden;
  white-space: nowrap;
  color: #000;
  text-overflow: ellipsis;
  margin-right: 10px;
  font-size: 18px;
  font-weight: 400;
}

.dropdown-notification .notification-item .time {
  color: #888;
}

.dropdown-notification .notification-item .title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
  margin-top: 2px;
  color: #555;
}

.notification-item:hover .who,
.notification-item:hover .time,
.notification-item:hover .title {
  color: #fff;
  transition: all 0.3s ease;
}

.flex-row {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 865px) {
  .es-header .nav.navbar-nav {
    width: 100%;

  }

  /* .anniversary-navbar .navbar-nav {
    left: 280px!important;
  } */
}

@media (max-width: 767px) {
  .es-header {
    height: 60px;
  }

  .es-header .navbar-header {
    text-align: center;
    float: none;
    height: 60px;
  }

  .es-header .navbar-header .navbar-brand {
    float: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 60px;
    height: 60px;
  }

  .es-header .navbar-header .navbar-brand>img {
    height: 40px;
  }

  .navbar-mobile .navbar-more {
    padding: 20px 15px;
  }

  .head-right {
    right: 0;
  }

  .nav.user-nav>li {
    height: 60px;
    line-height: 60px;
  }

  .nav.user-nav>li>a {
    line-height: 52px;
    padding: 0 12px;
    font-size: 14px;
  }

  .nav.user-nav>li>a.dropdown-toggle {
    padding: 8px;
  }

  .nav.user-nav>li>a.dropdown-toggle .avatar-vip {
    right: 0;
    bottom: 0;
  }

  .nav.user-nav>li>a.dropdown-toggle .avatar-face {
    width: 30px;
    height: 30px;
    overflow: hidden;
  }

  .nav.user-nav>li>a.dropdown-toggle .avatar-vip {
    width: 18px;
    height: 18px;
    overflow: hidden;
  }
}